import { IStylesObject, theme } from "../../theme/theme";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import { useEffect, useRef } from "react";
import React from "react";
import { IPropsLocations } from "../../types";
import { Box, Text } from "theme-ui";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({ nullTargetWarn: false });

const locationNumberArray = [
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
];

const styles: IStylesObject = {
  moduleTitle: {
    gridColumn: "2 / -2",
    textAlign: "start",
    pt: ["4rem", "5rem", "6rem"],
  },
  moduleWrapper: {
    position: "relative",
    width: "100vw",
    p: 0,
  },
  locationWrapper: {
    position: "relative",
    gridColumn: "2 / -2",
    height: "34vh",
  },
  featuredLocation: {
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "clamp(30px,4vw,50px)",
    height: `${100 / locationNumberArray.length}vh`,
  },
  location: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "clamp(30px,4vw,50px)",
    height: `${100 / locationNumberArray.length}vh`,
  },
  featuredCompany: {
    fontSize: "clamp(40px,5vw,80px)",
    color: "primaryGreen",
  },
  featuredCompanyContainer: {
    gridColumn: "2/-2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "17vh",
    height: "fit-content",
    width: "100%",
    pt: "17vh",
    opacity: 0,
  },
};

export const RctLocations = (props: IPropsLocations): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: `.locationWrapper`,
        pin: true,
        // markers: true,
        start: "top top",
        end: "+=3000px",
        scrub: true,
        toggleActions: "play none reverse none",
      },
    });
    locationNumberArray.map((name, i) => {
      // locations fly in from left and right
      tl.fromTo(
        `.${name}`,
        {
          x: i % 2 == 0 ? "100vw" : "-100vw",
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: i == 0 ? 3 : 2,
        },
        i
      );
    });
    tl
      // start to fade out non-featured locations
      .to(
        `.notFeaturedLocation`,
        {
          opacity: 0,
          duration: 3,
          height: 0,
          ease: "slow",
        },
        "<3"
      )
      // enlarge and color the featured location
      .to(
        ".featuredLocation",
        {
          color: `${theme.colors ? theme.colors.primaryGreen : null}`,
          fontSize: "clamp(40px,6vw,80px)",
          height: "17vh",
          duration: 3,
          ease: "slow",
        },
        "<"
      )
      // fade in featured company
      .to(
        ".featuredCompanyContainer",
        {
          opacity: 1,
          duration: 4,
          ease: "slow",
        },
        "<1"
      )
      .to(
        ".featuredCompanyContainer",
        {
          paddingTop: 0,
          duration: 3,
          ease: "slow",
        },
        "<1"
      );
  }, []);
  return (
    <ModuleWrapper
      className="locationsModuleWrapper"
      isFullWidth={true}
      sx={styles.moduleWrapper}
      padding={false}
    >
      <Text className="heading" sx={styles.moduleTitle} ref={ref} variant="h2">
        {props.title}
      </Text>
      <Box className="locationWrapper" sx={styles.locationWrapper}>
        {locationNumberArray.map((name, i) => {
          const key = `location${name}` as keyof IPropsLocations;
          return (
            <Text
              key={`location${i}`}
              as="p"
              className={`${name} ${
                props.featuredLocation == props[key]
                  ? "featuredLocation"
                  : "notFeaturedLocation"
              }`}
              variant="h3"
              sx={
                props.featuredLocation == props[key]
                  ? { ...styles.location }
                  : { ...styles.featuredLocation }
              }
            >
              {props[key]}
            </Text>
          );
        })}
        <Box
          className="featuredCompanyContainer"
          sx={styles.featuredCompanyContainer}
        >
          <Text
            variant="h2"
            as="p"
            className="featuredCompany"
            sx={styles.featuredCompany}
          >
            {props.featuredCompany}
          </Text>
        </Box>
      </Box>
    </ModuleWrapper>
  );
};
