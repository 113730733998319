import type { Theme, ThemeUIStyleObject } from "theme-ui";
import { grids } from "./grid";
import { useBreakpointIndex } from "@theme-ui/match-media";

export const isMobile = (): boolean => useBreakpointIndex() === 0;
export const isTablet = (): boolean => useBreakpointIndex() === 1;
export const isDesktop = (): boolean => useBreakpointIndex() === 2;
export const isLargeDesktop = (): boolean => useBreakpointIndex() === 3;
export const isDesktopOrLargeDesktop = (): boolean => useBreakpointIndex() > 1;
export const isMobileOrTablet = (): boolean => useBreakpointIndex() < 2;

type TCalculateStylesFunction = (...args: any) => ThemeUIStyleObject;

export interface IStylesObject {
  [key: string]: ThemeUIStyleObject;
}

export interface ICalculatedStylesObject {
  [key: string]: TCalculateStylesFunction;
}

export const theme: Theme = {
  breakpoints: ["600px", "1120px", "1300px"],
  fonts: {
    body: "Helvetica, sans-serif",
    heading: "Roboto, sans-serif",
  },
  colors: {
    text: "#000",
    textWhite: "#fff",
    background: "#fff",
    primaryBlue: "#5D6E83",
    primaryGreen: "#80C802",
    darkBlue: "#272D35",
    iconBorder: "border 2px solid #80C802",
  },
  buttons: {
    primary: {
      bg: "red",
    },
    secondary: {
      bg: "green",
    },
  },
  grids,
  text: {
    h1: {
      color: "text",
      fontSize: "min(6rem, 9vw)",
      lineHeight: "min(6rem, 9vw)",
      fontWeight: 700,
      letterSpacing: "min(1rem, 0.6vh)",
      fontFamily: "heading",
      textTransform: "uppercase",
      paddingBottom: "2vh",
    },
    h2: {
      color: "text",
      fontSize: "min(5rem, 7.5vw)",
      fontWeight: 700,
      fontFamily: "heading",
      textTransform: "uppercase",
      // paddingBottom: "2vh",
    },
    h2_5: {
      color: "text",
      fontSize: "min(4rem, 7vw)",
      fontWeight: 700,
      fontFamily: "heading",
      textTransform: "uppercase",
    },
    h3: {
      color: "text",
      fontSize: "min(2.75rem, 6vw)",
      fontWeight: 700,
      fontFamily: "heading",
      textTransform: "uppercase",
    },
    h4: {
      color: "text",
      fontSize: "min(2rem, 5vw)",
      fontWeight: 400,
      fontFamily: "heading",
    },
    footnotes: {
      color: "grey",
      fontSize: "min(1rem, 4vw)",
      fontWeight: 400,
      fontFamily: "heading",
    },
    bold: {
      color: "text",
      fontSize: "min(2rem, 5.5vw)",
      fontWeight: 700,
      fontFamily: "body",
    },
    bodyText: {
      color: "text",
      fontSize: "min(1.5rem, 5vw)",
      fontWeight: 400,
      fontFamily: "body",
      lineHeight: "min(1.8rem, 6vw)",
      letterSpacing: "1px",
    },
    greenBodyText: {
      color: "primaryGreen",
      fontSize: "min(1.5rem, 5vw)",
      fontWeight: 400,
      fontFamily: "body",
      lineHeight: "min(1.8rem, 6vw)",
      letterSpacing: "1px",
    },
    uncoloredBodyText: {
      fontSize: "min(1rem, 3vw)",
      fontWeight: 400,
      fontFamily: "body",
      lineHeight: "min(1.8rem, 6vw)",
      letterSpacing: "1px",
    },
    greenBold: {
      color: "primaryGreen",
      fontSize: "min(2rem, 5.5vw)",
      fontWeight: 700,
      fontFamily: "body",
    },
  },
  links: {
    anchorLink: {
      color: "text",
      fontSize: "min(1rem, 3vw)",
      fontWeight: 800,
      textTransform: "uppercase",
      cursor: "pointer",
      textDecoration: "none",
    },
    standard: {
      color: "text",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
};
