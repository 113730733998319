import { IStylesObject } from "./theme";

export const GRID_GAP_MOBILE = "0.5rem";
export const GRID_GAP_TABLET = "0.75rem";
export const GRID_GAP_DESKTOP = "1rem";

export const defaultGridGap = [
  GRID_GAP_MOBILE,
  GRID_GAP_TABLET,
  GRID_GAP_DESKTOP,
];

const NUMBER_MAIN_COLUMNS = 12;

export const MAIN_COLUMN_WIDTH = `minmax(0, calc(59rem / ${NUMBER_MAIN_COLUMNS}))`;

const MAX_CONTENT_WIDTH = "1400px";

const fullWidthGrid = {
  gridTemplateColumns: `minmax(0, 1fr) repeat(${NUMBER_MAIN_COLUMNS}, ${MAIN_COLUMN_WIDTH}) minmax(0, 1fr)`,
  gap: defaultGridGap,
  rowGap: [0, GRID_GAP_TABLET, GRID_GAP_DESKTOP],
  maxWidth: MAX_CONTENT_WIDTH,
  m: "auto",
  bg: "background",
};

const contentGrid = {
  gridColumn: "2 / -2",
  gridTemplateColumns: `repeat(${NUMBER_MAIN_COLUMNS}, 1fr)`,
  gap: defaultGridGap,
  rowGap: [0, GRID_GAP_TABLET, GRID_GAP_DESKTOP],
};

export const grids: IStylesObject = {
  fullWidthGrid,
  contentGrid,
};
