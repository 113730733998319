import { useViewportScroll, useTransform, motion } from "framer-motion";
import { Text, Box } from "theme-ui";
import {
  isDesktop,
  isDesktopOrLargeDesktop,
  isLargeDesktop,
  IStylesObject,
} from "../../theme/theme";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import { RctImage } from "../../components/Image/Image";
import React, { useEffect } from "react";
import { IPropsPhilosophy } from "../../types";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const styles: IStylesObject = {
  wrapper: {
    height: `100vh`,
    width: "100vw",
    position: "relative",
  },
  motto: {
    display: "flex",
    position: "absolute",
    right: 0,
    textAlign: "end",
    top: "50%",
    transform: "translate(0%,-50%)",
  },
  bannerImageWrapper: {
    position: "absolute",
    left: "-10vh",
    bottom: 0,
    width: "60vw",
    maxWidth: "950px",
    opacity: 0,
  },
  bannerImage: {
    width: "100%",
    height: "100%",
  },
  textWrapper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    width: ["100vw", "100vw", "50vw", "50vw"],
    right: "1vw",
    top: "50%",
    transform: "translate(0%,-50%)",
    opacity: 0,
    px: ["0.5rem", "0.5rem", 0, 0],
  },
  text: {
    color: "white",
    textAlign: ["justify", "right", "right", "right"],
    pb: "2vh",
  },
  banner: {
    color: "white",
    textAlign: ["justify", "right"],
  },
};

export const RctPhilosophy = ({
  motto,
  text,
  image,
}: IPropsPhilosophy): JSX.Element => {
  const splittedMotto = motto.split(" ");

  useEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: `.moduleWrapperPhilosophy`,
        pin: true,
        // markers: true,
        end: "+=2000",
        scrub: true,
        toggleActions: "play none reverse none",
      },
    });
    tl.fromTo(
      `.motto`,
      {
        y: "-100vh",
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
      }
    );
    tl.to(".motto", {
      opacity: 0,
      duration: 1,
    })
      .to(".bannerImageWrapper", {
        opacity: 1,
        duration: 3,
      })
      .to(
        ".textWrapper",
        {
          opacity: 1,
          duration: 3,
        },
        "<"
      );
  }, []);
  const desktop = isDesktopOrLargeDesktop();

  return (
    <ModuleWrapper
      isDark
      className="moduleWrapperPhilosophy"
      anchorLink="RctPhilosophy"
      isFullWidth={true}
      sx={styles.wrapper}
    >
      <Text className="motto" variant="h1" sx={styles.motto}>
        {splittedMotto[0]}
        <br />
        {splittedMotto[1]}
        <br />
        {splittedMotto[2]}
        <br />
        {splittedMotto[3]}
      </Text>
      <Box className="bannerImageWrapper" sx={styles.bannerImageWrapper}>
        {desktop ? (
          <RctImage src={image.file.url} sx={styles.bannerImage} />
        ) : null}
      </Box>
      <Box className="textWrapper" sx={styles.textWrapper}>
        <Text variant="h3" sx={styles.text}>
          {text}
        </Text>
        <Text variant="h4" sx={styles.banner}>
          Marcus Banner CEO – RealCore Group
        </Text>
      </Box>
    </ModuleWrapper>
  );
};
