import React from "react";
import { Box, Text } from "theme-ui";
import {
  ICalculatedStylesObject,
  isDesktopOrLargeDesktop,
  IStylesObject,
} from "../../theme/theme";

export interface IPropsGalleryCard {
  image: {
    file: {
      url: string;
    };
  };
  name: string;
  color: string;
  customColor: string;
  subTitle: string;
  subTitleColor: string;
  subTitleCustomColor: string;
  additionalStyles: string[];
}

const styles: IStylesObject = {
  child: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    backgroundSize: "cover",
    transition: "all .5s",
    transform: "scale(1.0)",
    backgroundPosition: "top",
    filter: "brightness(95%) contrast(95%) grayscale(3%)",
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  name: (color: string) => {
    return {
      position: "absolute",
      right: "1rem",
      bottom: "1rem",
      fontWeight: "bold",
      opacity: 0,
      color: color,
    };
  },
  backgroundImage: (imageSrc: string) => {
    return {
      backgroundImage: `url(${imageSrc})`,
    };
  },
  cardWrapper: (isDesktop: boolean) => {
    return {
      position: "relative",
      gridColumn: "span 4",
      aspectRatio: "4/5",
      overflow: "hidden",
      color: "text",
      ":hover": {
        ".greyscale": {
          transition: "all .5s",
          filter: isDesktop ? "brightness(60%) grayscale(1)" : "",
        },
        ".zoom": {
          transition: "all .5s",
          transform: isDesktop ? "scale(1.03)" : "",
        },
        "#Name": {
          opacity: isDesktop ? 1 : 0,
        },
        "#SubTitle": {
          opacity: isDesktop ? 1 : 0,
        },
      },
    };
  },
};

export const GalleryCard = (galleryCard: IPropsGalleryCard) => {
  const additionalStyles: string = galleryCard.additionalStyles
    .filter((string) => string !== "vanilla")
    .join(" ");
  const nameColor =
    galleryCard.color !== "custom"
      ? galleryCard.color
      : galleryCard.customColor;

  const desktop = isDesktopOrLargeDesktop();

  return (
    <Box sx={calculatedStyles.cardWrapper(desktop)}>
      <Box
        className={`${additionalStyles}`}
        sx={{
          ...styles.child,
          ...calculatedStyles.backgroundImage(
            `${galleryCard.image.file.url}?fit=pad&h=500`
          ),
        }}
      ></Box>
      <Text
        as="p"
        id="Name"
        variant="bodyText"
        sx={calculatedStyles.name(nameColor)}
      >
        {galleryCard.name}
      </Text>
    </Box>
  );
};
