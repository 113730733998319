const impressum =`<h2 id="impressum">Impressum</h2>
<h4 id="angaben-gem-5-tmg">Angaben gemäß §5 TMG</h4>
<p>Realcore Technology GmbH</p>
<p>Werner-Von-Siemens-Allee 4</p>
<p>66115 Saarbrücken</p>
<p><strong>Handelsregister:</strong> HRB 101040</p>
<p><strong>Registergericht:</strong> Amtsgericht Saarbrücken</p>
<p><strong>Vertreten durch: </strong>Frank Bollinger, Dr. Uwe Beister</p>
<h4 id="kontakt">Kontakt</h4>
<p><strong>Telefon:</strong> +49151612890320</p>
<p><strong>E-Mail:</strong> saarbruecken@realcore.de</p>
<p><strong>Umsatzsteuer-ID:</strong> DE289537979</p>`

export default impressum