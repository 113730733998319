import { Box, Grid, Heading, Text } from "theme-ui";
import {
  isLargeDesktop,
  isDesktop,
  isTablet,
  isDesktopOrLargeDesktop,
  IStylesObject,
} from "../../theme/theme";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import {
  GalleryCard,
  IPropsGalleryCard,
} from "../../components/GalleryCard/GalleryCard";
import React, { useEffect, useRef, useState } from "react";
import { IPropsGalleryCards } from "../../types";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, FreeMode } from "swiper";

export interface IPropsImageGallery {
  galleryCards: IPropsGalleryCard[];
  title: string;
}
const styles: IStylesObject = {
  contentWrapper: {
    gridColumn: "2 / -2",
    gridTemplateColumns: "repeat(12, 1fr)",
  },
  image: {
    gridColumn: "span 4",
  },
  galleryTitle: {
    gridColumn: "2 / -2",
    textAlign: "start",
  },
  mobileName: {
    pt: 3,
  },
};

export const RctImageGallery = ({
  galleryCards,
  title,
}: IPropsGalleryCards): JSX.Element => {
  const desktop = isDesktopOrLargeDesktop();
  const checkTablet = isTablet();
  const checkDesktop = isDesktop();
  const checkLargeDesktop = isLargeDesktop();
  let slidesPerView: number;
  if (checkLargeDesktop) slidesPerView = 4;
  else if (checkDesktop) slidesPerView = 3;
  else if (checkTablet) slidesPerView = 2;
  else slidesPerView = 1;
  return (
    <ModuleWrapper anchorLink="RctImageGallery" isFullWidth={true}>
      <Grid sx={styles.galleryTitle}>
        <Heading variant="h2">{title}</Heading>
      </Grid>
      <Box sx={styles.contentWrapper}>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={slidesPerView > 1 ? 20 : 0}
          freeMode={true}
          navigation={true}
          modules={[FreeMode, Navigation]}
          className="mySwiper"
          loop
          slidesPerGroup={slidesPerView}
          // loopFillGroupWithBlank={true}
        >
          {galleryCards.map((galleryCard, index) => (
            <SwiperSlide key={`gallerySlide${index}`}>
              <GalleryCard {...galleryCard} />
              {!desktop ? (
                <Box>
                  <Text sx={styles.mobileName} variant="bodyText" as="h3">
                    {galleryCard.name}
                  </Text>
                </Box>
              ) : null}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </ModuleWrapper>
  );
};
