import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Box, Text } from "theme-ui";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import { TechnologyCard } from "../../components/TechnologyCard/technologyCard";
import { defaultGridGap } from "../../theme/grid";
import {
  isDesktop,
  isDesktopOrLargeDesktop,
  isLargeDesktop,
  isMobile,
  isMobileOrTablet,
  isTablet,
  IStylesObject,
} from "../../theme/theme";
import React from "react";
import { IPropsTechnology } from "../../types";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FreeMode, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../../devAssets/images/LogoSmall.jpg";
const MotionText = motion(Text);

const styles: IStylesObject = {
  moduleWrapper: {
    minHeight: ["80vh", "40vh", "auto", "auto"],
    pt: ["2rem", "5rem", "3rem"],
    px: [0, "4rem", 0, 0],
  },
  technologyText: {
    position: "relative",
    minHeight: ["40vh", "20vh", "80vh", "80vh"],
    gridColumn: ["2 /  -2", "2 / -2", "2 / span 5", "2 / span 5"],
    textAlign: "justify",
    display: "flex",
    alignItems: "center",
    hyphens: "auto",
    gridRow: [2, 2, 1, 1],
  },
  text: {
    position: "absolute",
    color: "white",
    pt: [5, 0, 0, 0],
  },
  technologyCardsWrapper: {
    gridColumn: "8 / span 6",
    justifySelf: "center",
    alignSelf: "center",
    height: "fit-content",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRow: "repeat(4, 1fr)",
    gridGap: defaultGridGap,
  },
  technologyCardsWrapperHandheld: {
    gridColumn: "2 / -2",
    height: "20vh",
  },
};

const variants = {
  inView: {
    opacity: 1,
  },
  default: {
    opacity: 0,
    transition: { delay: 0 },
  },
  initial: {
    opacity: 0,
  },
};
const TEXT_DURATION = 0.2;
const TEXT_TRANSITION = {
  ease: "easeIn",
  duration: TEXT_DURATION,
  delay: TEXT_DURATION,
};

export const RctTechnology = ({
  technologyText,
  technologyCards,
}: IPropsTechnology) => {
  const isBrowser = typeof window !== "undefined";
  const [activeText, setActiveText] = useState(technologyText);
  const desktop = isDesktopOrLargeDesktop();
  const [activeItem, setActiveItem] = useState(0);

  const [timer, setTimer] = useState<any>();
  const carouselRef = useRef<HTMLDivElement>(null);
  const carouselWidth =
    carouselRef.current?.clientWidth ?? isBrowser ? window.innerWidth : 0;
  const itemPercentage = (100 / carouselWidth) * 200;
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(
    itemPercentage < 75 ? itemPercentage : 75
  );

  useEffect(() => {
    isBrowser &&
      window.addEventListener(
        "resize",
        () => {
          const carouselWidth =
            carouselRef.current?.clientWidth ?? isBrowser
              ? window.innerWidth
              : 0;
          const itemPercentage = (100 / carouselWidth) * 200;
          setCenterSlidePercentage(itemPercentage < 75 ? itemPercentage : 75);
        },
        true
      );
  }, []);

  function getSlideDataIndex(swipe: any) {
    var activeIndex = swipe.activeIndex;
    var slidesLen = swipe.slides.length;
    if (swipe.params.loop) {
      switch (swipe.activeIndex) {
        case 0:
          activeIndex = slidesLen - 3;
          break;
        case slidesLen - 1:
          activeIndex = 0;
          break;
        default:
          --activeIndex;
      }
    }
    return activeIndex;
  }

  return (
    <ModuleWrapper
      anchorLink="RctTechnology"
      isFullWidth={true}
      sx={styles.moduleWrapper}
      isDark={true}
    >
      {desktop ? (
        <Box sx={styles.technologyCardsWrapper}>
          {technologyCards.map((technologyCard, index) => (
            <TechnologyCard
              key={`TechnologyCard${index}`}
              {...technologyCard}
              onHoverStart={() => {
                setActiveText("");
                setTimer(
                  setTimeout(
                    () => setActiveText(technologyCard.description),
                    TEXT_DURATION / 2
                  )
                );
              }}
              onHoverEnd={() => {
                setActiveText("");
                setTimer(
                  setTimeout(
                    () => setActiveText(technologyText),
                    TEXT_DURATION / 2
                  )
                );
              }}
            />
          ))}
        </Box>
      ) : (
        <Box sx={styles.technologyCardsWrapperHandheld} ref={carouselRef}>
          {/*           <Text variant="bold"> {technologyText}</Text>
           */}{" "}
          <Swiper
            slidesPerView={1}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Navigation]}
            onSlideChange={(swiper) => {
              setActiveItem(getSlideDataIndex(swiper));
              setActiveText(
                technologyCards[getSlideDataIndex(swiper)].description
              );
            }}
            loop
          >
            {technologyCards.map((card, index) => (
              <SwiperSlide key={`technologySwiperSlide${index}`}>
                <TechnologyCard
                  {...card}
                  activeItem={activeItem === index + 1}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
      <Box sx={styles.technologyText}>
        <AnimatePresence>
          <MotionText
            key={activeText}
            exit={variants.default}
            variant="bodyText"
            sx={styles.text}
            animate={"inView"}
            initial="initial"
            transition={TEXT_TRANSITION}
            variants={variants}
          >
            {activeText}
          </MotionText>
        </AnimatePresence>
      </Box>
    </ModuleWrapper>
  );
};
