import { Text, Box, Link } from "theme-ui";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import { IStylesObject } from "../../theme/theme";
import React from "react";
import { IPropsPassion } from "../../types";
import { AiOutlineArrowRight } from "react-icons/ai";
const styles: IStylesObject = {
  wrapper: {
    position: "relative",
    width: "100vw",
    height: "50vh",
  },
  textWrapper: {
    position: "absolute",
    gridColumn: "2 / -2",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    "& > span": {
      color: "white",
    },
    "& > span:first-of-type": {
      pb: "3rem",
    },
  },
  text1: {
    color: "white",
  },
  text2: { pb: "2rem" },
  applyWrapper: {
    display: "flex",
    alignItems: "center",
  },
  apply: {
    // fontSize: "min(5rem, 7.5vw)",
    textDecoration: "none",
    cursor: "pointer",
    color: "#80C802",
    ":hover": {
      color: "#90E103",
    },
  },
};

export const RctPassion = ({ text1, text2 }: IPropsPassion) => {
  return (
    <ModuleWrapper
      isFullWidth={true}
      sx={styles.wrapper}
      isDark={true}
      anchorLink="RctContact"
    >
      <Box sx={styles.textWrapper}>
        <Text variant="bold" sx={styles.text1}>
          {text1}
        </Text>
        <Text variant="h2" sx={styles.text2}>
          {text2}
        </Text>
        <Box sx={styles.applyWrapper}>
          <Text variant="bold" sx={styles.text1}>
            Dann wirf einen Blick auf unsere{" "}
            <Link
              sx={styles.apply}
              href="https://realcore.dvinci-hr.com/de/p/NEXT_2022/jobs"
              target="_blank"
            >
              Stellenangebote
            </Link>
            .
          </Text>
        </Box>
      </Box>
    </ModuleWrapper>
  );
};
