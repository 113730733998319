import { ThemeUIStyleObject } from "theme-ui";
import { Image } from "theme-ui";
import { IStylesObject } from "../../theme/theme";
import React from "react";
export interface IPropsRctImage {
  src: string;
  sx?: ThemeUIStyleObject;
  style?: IStylesObject;
  className?: string;
}

export const RctImage = ({
  src,
  sx,
  style,
  className,
}: IPropsRctImage): JSX.Element => {
  return <Image className={className} style={style} src={src} sx={sx}></Image>;
};
