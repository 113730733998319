import { motion } from "framer-motion";
import { Box } from "theme-ui";
import { ICalculatedStylesObject, IStylesObject } from "../../theme/theme";
import { RctImage } from "../Image/Image";
import React from "react";
import { IPropsTechnologyCard } from "../../types";

const MotionBox = motion(Box);

const styles: IStylesObject = {
  outerWrapper: {
    width: "100%",
    height: "auto",
    minHeight: "150px",
    minWidth: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    img: {
      filter: "brightness(100%) grayscale(0.9)",
      transition: "all .2s",
      transform: "scale(1.0)",
    },
    "&: hover": {
      img: {
        filter: "none",
        transition: "all .2s",
        transform: "scale(1.2)",
      },
    },
    cursor: "pointer",
  },
  image: {
    objectFit: "cover",
    display: "block",
    maxWidth: "100px",
    maxHeight: "100px",
    width: "auto",
    height: "auto",
  },
  technologyCardWrapper: {
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100px",
    maxWidth: "100px",
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  outerWrapper: (isActive: boolean) => {
    return isActive
      ? {
          img: {
            filter: "none",
            transition: "all .2s",
            transform: "scale(1.2)",
          },
        }
      : {};
  },
};

export const TechnologyCard = ({
  image,
  onHoverStart,
  onHoverEnd,
  activeItem,
}: IPropsTechnologyCard) => {
  return (
    <MotionBox
      sx={{
        ...styles.outerWrapper,
        ...calculatedStyles.outerWrapper(activeItem),
      }}
      onHoverStart={onHoverStart}
      onHoverEnd={(e) => {
        e.stopPropagation();
        onHoverEnd && onHoverEnd();
      }}
    >
      <MotionBox sx={styles.technologyCardWrapper}>
        <RctImage src={image.file.url} sx={styles.image} />
      </MotionBox>
    </MotionBox>
  );
};
