import { Text, Grid, Box, Link } from "theme-ui";
import { IStylesObject } from "../../theme/theme";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import { RctImage } from "../../components/Image/Image";
import React, { useEffect, useState } from "react";
import { IPropsContactInformation } from "../../types";
import { Popup } from "../../components/Popup/Popup";
import marked from "marked";
import impressum from "../../../legal/impressum.js";
import datenschutz from "../../../legal/datenschutz";

const styles: IStylesObject = {
  wrapper: {
    position: "relative",
    height: "fit-content",
    width: "100vw",
    pt: ["2rem", "4rem", "6rem", "6rem"],
  },
  contentWrapper: {
    gridColumn: "2 / -2",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridTemplateRows: "min-content",
    height: "min-content",
  },
  heading: {
    color: "textWhite",
    height: "fit-content",
    gridColumn: ["1/-1", "1/7", "1/7"],
    textAlign: "left",
  },
  image: {
    width: "auto",
    alignSelf: "center",
    objectFit: "contain",
    gridColumn: ["1 / -1", "1 /5", "1/7", "1/7"],
    justifySelf: "center",
    overflow: "hidden",
    display: ["block", "block", "block"],
  },
  text: {
    display: "flex",
    alignSelf: ["start", "center", "center"],
    flexDirection: "column",
    gridColumn: ["1/-1", "6 / -1", "8/-1", "8/-1"],
    "& > span, & > a": {
      alignSelf: "flex-start",
      color: "textWhite",
    },
    "& > *": { color: "textWhite" },
    justifySelf: "flex-start",
    wordBreak: "break-word",
    textAlign: "left",
    pb: "1rem",
  },
  legalInformationWrapper: {
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr 1fr", "4fr 3fr"],
    gridTemplateRows: ["fit-content fit-content", "1fr", "1fr"],
    borderTop: "1px solid gray",
    marginTop: "2vh",
    paddingTop: "1vh",
    gridColumn: "1 / -1",
    height: "fit-content",
    pb: ["2rem", "0.5rem", "0.5rem"],
  },
  copyrightWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: ["center", "space-between"],
    gridRow: [2, "auto", "auto"],
  },
  copyright: {
    color: "textWhite",
    cursor: "pointer",
  },
  legalLinksWrapper: {
    mt: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: ["space-around", "end"],
    gridRow: [1, "auto", "auto"],
  },
  legalLinks: {
    color: "textWhite",
    cursor: "pointer",
  },
};

export const RctContact = ({
  title,
  companyName,
  // managingPartnerName,
  addressLine1,
  addressLine2,
  email,
  image,
  copyright,
}: IPropsContactInformation): JSX.Element => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const handlePopup = (text: string) => {
    setPopupContent(text);
    setShowPopup(true);
  };

  return (
    <ModuleWrapper
      isFullWidth={true}
      sx={styles.wrapper}
      isDark={true}
      anchorLink="RctContact"
      padding={false}
    >
      <Popup
        content={popupContent}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
      />
      <Grid sx={styles.contentWrapper}>
        <Text variant="h2" sx={styles.heading}>
          {title}
        </Text>
        <RctImage src={image.file.url} sx={styles.image} />

        <Box sx={styles.text}>
          <Text variant="h4">{companyName}</Text>
          {/* <Text variant="bold">{managingPartnerName}</Text> */}
          <Text variant="h4">{addressLine1}</Text>
          <Text variant="h4">{addressLine2}</Text>
          <Text variant="bold">
            <Link
              variant="standard"
              sx={{ color: "primaryGreen" }}
              href={"mailto:" + email}
            >
              {email}
            </Link>
          </Text>
        </Box>
        <Box sx={styles.legalInformationWrapper}>
          <Box sx={styles.copyrightWrapper}>
            <Text sx={styles.copyright} variant="footnotes">
              {copyright}
            </Text>
          </Box>
          <Box sx={{ ...styles.legalLinksWrapper, py: "1rem" }}>
            <Text
              variant="footnotes"
              sx={{ ...styles.legalLinks, paddingRight: "3vw" }}
              onClick={() => handlePopup(impressum)}
            >
              Impressum
            </Text>
            <Text
              variant="footnotes"
              sx={styles.legalLinks}
              onClick={() => handlePopup(datenschutz)}
            >
              Datenschutz
            </Text>
          </Box>
        </Box>
      </Grid>
    </ModuleWrapper>
  );
};
