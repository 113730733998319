import React, { ReactNode, useEffect, useState } from "react";
import { Box, Grid, Link, ThemeUIStyleObject } from "theme-ui";
import DarkBG from "../../devAssets/images/DarkBG.png";
import { IStylesObject } from "../../theme/theme";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { GrFormClose } from "react-icons/gr";

export interface IPropsPopup {
  content: string;
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
}

const styles: IStylesObject = {
  outerBox: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,
    width: "100vw",
    height: "100vh",
    bg: "rgba(0,0,0,0.5)",
  },
  innerBox: {
    pb: "25px",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    zIndex: 101,
    transform: "translate(-50%,-50%)",
    width: ["90vw", "80vw", "60vw", "60vw"],
    maxHeight: ["90vh", "80vh", "60vh", "60vh"],
    bg: "white",
    borderRadius: "10px",
  },
  iconWrapper: {
    display: "flex",
    height: "50px",
    width: "100%",
    justifyContent: "end",
    color: "red",
    "&>svg": {
      "&>*": {
        stroke: "primaryGreen",
      },
    },
  },
  textWrapper: {
    p: "10px 25px 0px 25px",
    overflow: "scroll",
  },
};
export const Popup = ({
  content,
  showPopup,
  setShowPopup,
}: IPropsPopup): JSX.Element => {
  return (
    <>
      {showPopup ? (
        <Box sx={styles.outerBox} onClick={() => setShowPopup(false)}>
          <Box sx={styles.innerBox}>
            <Box sx={styles.iconWrapper}>
              <GrFormClose
                style={{
                  height: "50px",
                  width: "50px",
                  cursor: "pointer",
                }}
                onClick={() => setShowPopup(false)}
              />
            </Box>
            <Box sx={styles.textWrapper}>
              <div
                style={{ textAlign: "start", overflow: "scroll" }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
