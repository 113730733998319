import { Box, Heading, Text } from "theme-ui";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import { IStylesObject } from "../../theme/theme";
import { BsPlusLg } from "react-icons/bs";
import React, { useEffect } from "react";
import { IPropsWork } from "../../types";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const styles: IStylesObject = {
  sectionWrapper: {
    gridColumn: "2 / -2",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
  },
  titleWrapper: {
    gridColumn: ["2 / -2", "2 / -2", "2 / 5"],
    pt: ["2rem", "2rem", "1rem", 0],
    pb: ["1rem", "1rem", 0, "1.5rem"],
    mb: ["1rem", "1rem", "1rem", 0],
    display: "flex",
    maxWidth: ["100vw", "100vw", "30vw"],
    textAlign: "start",
    width: ["100%", "100%", "50%", "auto"],
    borderBottom: ["3px solid", "3px solid", 0, 0],
    borderColor: ["primaryGreen", "primaryGreen", "primaryGreen"],
  },
  pointsWrapper: {
    gridColumn: ["2 / -2", "2 / -2", "6 / -2"],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    mb: ["2rem", "2rem", "4rem"],
    "&:last-of-type": {
      mb: [0, 0, "4rem"],
    },
  },
  singlePointWrapper: {
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
    pt: "1rem",
    pb: ["0rem", "0rem", "3rem"],
  },
  textWrapper: {
    display: "table",
    pl: "1rem",
  },
  text: {
    textAlign: "start",
    display: "table-cell",
    verticalAlign: "middle",
  },
  moduleWrapper: {},
  marker: {
    width: [16, 20, 24],
    height: [16, 20, 24],
    minWidth: [16, 20, 24],
    minHeight: [16, 20, 24],
    pt: "0.2rem",
    mr: "1rem",
  },
  markerWrapper: {
    display: "flex",
    alignItems: "center",
  },
};
interface IPropsPoints {
  id: string;
  points: string[];
}

const Points = ({ points, id }: IPropsPoints) => {
  return (
    <Box sx={styles.pointsWrapper}>
      {points.map((point, index) => {
        return (
          <Box
            className={`${id}${index + 1}`}
            key={`${point}${index}key`}
            sx={styles.singlePointWrapper}
          >
            <Box sx={styles.markerWrapper}>
              <BsPlusLg size="clamp(16px, 6vw, 32px)" color="#80C802" />
            </Box>
            <Box sx={styles.textWrapper}>
              <Text as="p" variant="bodyText" sx={styles.text}>
                {point}
              </Text>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export const RctWorks = ({
  workBalanceTitleFirst,
  workBalanceTitle,
  workBalancePoints,
  benefitsTitleFirst,
  benefitsTitle,
  benefitsPoints,
  serviceTitleFirst,
  serviceTitle,
  servicePoints,
}: IPropsWork) => {
  const sections = [
    {
      id: "workBalance",
      title1: workBalanceTitleFirst,
      title2: workBalanceTitle,
      points: workBalancePoints,
    },
    {
      id: "benefits",
      title1: benefitsTitleFirst,
      title2: benefitsTitle,
      points: benefitsPoints,
    },
    {
      id: "service",
      title1: serviceTitleFirst,
      title2: serviceTitle,
      points: servicePoints,
    },
  ];

  useEffect(() => {
    // alternative solution with fading only
    // sections.map((section) => {
    //   gsap.set(`.${section.id}Title`, {
    //     opacity: 0.1,
    //   });
    //   section.points.map((_, index) => {
    //     gsap.set(`.${section.id}${index + 1}`, {
    //       opacity: 0.1,
    //     });
    //   });

    //   let tl = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: `.${section.id}Wrapper`,
    //       // markers: true,
    //       start: "top 80%",
    //       end: "bottom 20%",
    //       scrub: true,
    //       toggleActions: "play none reverse none",
    //     },
    //   });
    //   tl.to(`.${section.id}Title`, {
    //     opacity: 1,
    //     duration: 4,
    //   });
    //   section.points.map((_, pointIndex) => {
    //     tl.to(
    //       `.${section.id}${pointIndex + 1}`,
    //       {
    //         opacity: 1,
    //         duration: 4,
    //       },
    //       "<2"
    //     );
    //   });
    //   tl.to(
    //     `.${section.id}Title`,
    //     {
    //       opacity: 0.1,
    //       duration: 4,
    //     },
    //     "<10"
    //   );
    //   section.points.map((_, pointIndex) => {
    //     tl.to(
    //       `.${section.id}${pointIndex + 1}`,
    //       {
    //         opacity: 0.1,
    //         duration: 4,
    //       },
    //       "<2"
    //     );
    //   });
    // });
    sections.map((section) => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: `.${section.id}Wrapper`,
          // markers: true,
          start: "top bottom",
          end: "bottom 80%",
          scrub: true,
          toggleActions: "play none reverse none",
        },
      });
      tl.from(`.${section.id}Title`, {
        x: "-100vw",
        // y: "50vh",
        duration: 3,
      }).from(
        `.${section.id}Title`,
        {
          opacity: 0,
          scale: 0.5,
          duration: 4,
        },
        "<"
      );
      section.points.map((_, pointIndex) => {
        tl.from(
          `.${section.id}${pointIndex + 1}`,
          {
            x: "100vw",
            duration: 3,
          },
          "<1"
        ).from(
          `.${section.id}${pointIndex + 1}`,
          {
            opacity: 0,
            scale: 0.5,
            duration: 4,
          },
          "<"
        );
      });
    });
  }, []);

  return (
    <ModuleWrapper
      className="workWrapper"
      anchorLink="RctWork"
      isFullWidth={false}
      sx={styles.moduleWrapper}
    >
      {sections.map((section, index) => {
        return (
          <Box
            sx={styles.sectionWrapper}
            className={`${section.id}Wrapper`}
            key={`${section}${index}`}
          >
            <Box className={`${section.id}Title`} sx={styles.titleWrapper}>
              <Heading as="h2" variant="h3">
                {section.title1} {section.title2}
              </Heading>
            </Box>
            <Points points={section.points} id={section.id} />
          </Box>
        );
      })}
    </ModuleWrapper>
  );
};
