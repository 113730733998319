import { Box } from "theme-ui";
import { isDesktop, isLargeDesktop, IStylesObject } from "../../theme/theme";
import { RctContact } from "../../modules/RctContact/RctContact";

import { SocialMedia } from "../../components/SocialMedia/SocialMedia";
import { SocialMediaMobile } from "../../components/SocialMedia/SocialMediaMobile";

import React from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { ILandingPage } from "../../types";
import { ModuleRenderer } from "../../components/ModulesRenderer/ModulesRenderer";

const styles: IStylesObject = {
  landingPage: {
    scrollBehavior: "smooth",
    overflow: "hidden",
  },
};

export const LandingPage = ({ contentfulLandingPage }: ILandingPage) => {
  // TODO: put this somewhere else / make responsive?
  // necessity for automatically breaking text
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";
  isBrowser && document.documentElement.setAttribute("lang", "de");
  // var resizeTimeout: any;
  // isBrowser &&
  //   window.addEventListener("resize", function (event) {
  //     clearTimeout(resizeTimeout);
  //     resizeTimeout = setTimeout(function () {
  //       window.location.reload();
  //     }, 1000);
  //   });
  return (
    <Box sx={styles.landingPage}>
      {contentfulLandingPage &&
        contentfulLandingPage.module.map((module, index) => {
          return (
            <ModuleRenderer
              key={`${module.id}${index}`}
              module={module}
            ></ModuleRenderer>
          );
        })}
    </Box>
  );
};

export default LandingPage;
