import { useEffect, useRef, useState } from "react";
import { Box, Grid, Heading, Text } from "theme-ui";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import { IStylesObject } from "../../theme/theme";
import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IPropsValues } from "../../types";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const styles: IStylesObject = {
  refWrapper: {
    gridColumn: "2/-2",
  },
  moduleTitle: {
    pb: 0,
  },
  moduleTitleWrapper: {
    gridColumn: "2 / -2",
    width: "100vw",
    height: "min-content",
    display: "flex",
    justifyContent: "start",
  },
  valueWrapper: {
    cursor: "pointer",
    gridColumn: "2 / -2",
    mt: "5rem",
    mb: [0, 0, "4rem"],
    "&:first-of-type": {
      mt: "4rem",
    },
    overflow: "hidden",
  },
  titleWrapper: {
    display: "grid",
    columnGap: "1rem",
    alignItems: "center",
    gridTemplateColumns: "min-content 1fr",
  },
  textWrapper: {
    gridColumn: "2 / -2",
    display: "flex",
    alignItems: "start",
    pb: "2rem",
    "&:last-of-type": {
      pb: 0,
    },
  },
  title1: {
    textAlign: "start",
    display: ["block", "block", "table-caption"],
    fontWeight: 400,
  },
  title2: {
    textAlign: "start",
    color: "primaryGreen",
    fontWeight: 700,
  },
  text: {
    textAlign: "start",
  },
};

export const RctValues = (props: IPropsValues) => {
  const ref = useRef<HTMLDivElement>(null);
  const reffy = useRef();

  const [isVisible, setIsVisible] = useState(0);
  const [fadeIn, setFadeIn] = useState();

  const valuesArray = [
    "sustainability",
    "motivated",
    "innovation",
    "appreciation",
    "trust",
  ];

  useEffect(() => {
    // @ts-ignore
    reffy.current = gsap
      .timeline({ paused: true })
      .to(`.sustainabilityText`, {
        duration: 3,
        color: "red",
      })
      .to(`.${fadeIn}`, {
        duration: 3,
        opacity: 1,
      });

    valuesArray.map((name, i) => {
      gsap.set(`.${name}Title1`, {
        color: "#000",
        opacity: 0,
        // y: "10rem",
      });
      gsap.set(`.${name}Title2`, {
        color: "#000",
        opacity: 0,
        // y: "10rem",
      });
      gsap.set(`.${name}Text`, {
        color: "#000",
        opacity: 0,
        // y: "10rem",
      });
      gsap.set(`.${name}Icon`, {
        color: "#000",
        opacity: 0,
        // y: "10rem",
      });
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: `.${name}`,
          // markers: true,
          scrub: true,
          start: "top bottom",
          end: "bottom center",
          toggleActions: "play none reverse none",
        },
      });
      tl.to(
        `.${name}Title1`,
        {
          duration: 5,
          opacity: 1,
          // y: 0,
        },
        "<"
      );
      tl.to(
        `.${name}Title2`,
        {
          duration: 5,
          color: "#80C802",
          opacity: 1,
          // y: 0,
        },
        "<"
      );
      tl.to(
        `.${name}Text`,
        {
          duration: 5,
          opacity: 1,
          // y: 0,
        },
        "<"
      );
      tl.to(
        `.${name}Icon`,
        {
          duration: 5,
          opacity: 1,
          // y: 0,
        },
        "<"
      );
    });
  });

  useEffect(() => {
    const cachedRef = ref.current;

    const observer = new IntersectionObserver(() => setIsVisible(0));
    if (cachedRef) {
      observer.observe(cachedRef);
    }

    return () => {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  const handleClick = (i: number, fadeIitem: string) => {
    if (i == isVisible) {
      setIsVisible(0);
    } else {
      setIsVisible(i);
    }
    setFadeIn(fadeIitem);
    // @ts-ignore
    reffy.current.play();
  };

  return (
    <ModuleWrapper
      anchorLink="RctValues"
      isFullWidth={false}
      sx={styles.moduleWrapper}
      className="RctValuesWrapper"
    >
      <Box ref={ref} sx={styles.refWrapper}>
        <Box sx={styles.moduleTitleWrapper}>
          <Text className="heading" sx={styles.moduleTitle} variant="h2">
            {props.title}
          </Text>
        </Box>
        {valuesArray.map((name, i) => {
          const key1 = `${name}Title1` as keyof IPropsValues;
          const key2 = `${name}Title2` as keyof IPropsValues;
          const key3 = `${name}Text` as keyof IPropsValues;
          const key4 = `${name}Icon` as keyof IPropsValues;
          return (
            <React.Fragment key={i}>
              <Box
                className={name}
                sx={styles.valueWrapper}
                onClick={() => handleClick(i + 1, key3)}
              >
                {isVisible === i + 1 ? (
                  <Box sx={styles.titleWrapper}>
                    <IoIosArrowUp
                      className={key4}
                      size="clamp(35px,6vw, 60px)"
                    />
                    <Text className={key1} sx={styles.title1} variant="h3">
                      {props[key1]}
                    </Text>
                  </Box>
                ) : null}
                <Box sx={styles.titleWrapper}>
                  {!(isVisible === i + 1) ? (
                    <IoIosArrowDown
                      className={key4}
                      size="clamp(35px,6vw, 60px)"
                    />
                  ) : null}
                  <Text
                    className={key2}
                    sx={styles.title2}
                    as="h2"
                    variant="h2_5"
                  >
                    {props[key2]}
                  </Text>
                </Box>
                {isVisible === i + 1 ? (
                  <Box className={key3} sx={styles.textWrapper}>
                    <Text variant="bodyText" sx={styles.text}>
                      {props[key3]}
                    </Text>
                  </Box>
                ) : null}
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </ModuleWrapper>
  );
};
