import React, { ReactNode } from "react";
import { RctContact } from "../../modules/RctContact/RctContact";
import { RctImageGallery } from "../../modules/RctImageGallery/RctImageGallery";
import { RctLogoTitle } from "../../modules/RctLogoTitle/RctLogoTitle";
import { RctPassion } from "../../modules/RctPassion/RctPassion";
import { RctPhilosophy } from "../../modules/RctPhilosophy/RctPhilosophy";
import { RctTechnology } from "../../modules/RctTechnology/RctTechnology";
import { RctValues } from "../../modules/RctValues/RctValues";
import { RctWorks } from "../../modules/Work/RctWork";
import { RctLocations } from "../../modules/RctLocations/RctLocations";
import { SocialMedia } from "../../modules/RctSocialMedia/SocialMedia";
import {
  IPropsContactInformation,
  IPropsGalleryCards,
  IPropsLogoTitle,
  IPropsPassion,
  IPropsPhilosophy,
  IPropsTechnology,
  IPropsTechnologyCard,
  IPropsValues,
  IPropsWork,
  TAllModules,
  IPropsLocations,
  IPropsContactLinks,
} from "../../types";

export interface IPropsModulesRenderer {
  module: TAllModules;
}

export const ModuleRenderer = ({
  module,
}: IPropsModulesRenderer): JSX.Element => {
  const getModule = (module: TAllModules) => {
    switch (module.internal.type) {
      case "ContentfulLogoTitleModul":
        return <RctLogoTitle {...(module as IPropsLogoTitle)}></RctLogoTitle>;
      case "ContentfulOurPhilosophy":
        return (
          <RctPhilosophy {...(module as IPropsPhilosophy)}></RctPhilosophy>
        );
      case "ContentfulOurValues":
        const valueModule = module as any;
        const formattedOurValues: IPropsValues = {
          ...valueModule,
          appreciationText: valueModule.appreciationText.appreciationText,
          innovationText: valueModule.innovationText.innovationText,
          motivatedText: valueModule.motivatedText.motivatedText,
          sustainabilityText: valueModule.sustainabilityText.sustainabilityText,
          trustText: valueModule.trustText.trustText,
        };
        return (
          <RctValues {...(formattedOurValues as IPropsValues)}></RctValues>
        );
      case "ContentfulOurPassion":
        return <RctPassion {...(module as IPropsPassion)}></RctPassion>;
      case "ContentfulOurWork":
        return <RctWorks {...(module as IPropsWork)}></RctWorks>;
      case "ContentfulImageGallery":
        return (
          <RctImageGallery
            {...(module as IPropsGalleryCards)}
          ></RctImageGallery>
        );
      case "ContentfulTechnologyGallery":
        const technologyModule = module as any;
        const formattedTechnology: IPropsTechnology = {
          ...technologyModule,
          technologyText: technologyModule.technologyText.technologyText,
          technologyCards: technologyModule.technologyCards.map(
            (technologyCard: IPropsTechnologyCard, index: number) => ({
              ...technologyCard,
              description:
                technologyModule.technologyCards[index].description.description,
            })
          ),
        };
        return <RctTechnology {...(formattedTechnology as IPropsTechnology)} />;
      case "ContentfulContactInformation":
        return (
          <RctContact {...(module as IPropsContactInformation)}></RctContact>
        );
      case "ContentfulLocations":
        return <RctLocations {...(module as IPropsLocations)}></RctLocations>;
      case "ContentfulContactLinks":
        return <SocialMedia {...(module as IPropsContactLinks)}></SocialMedia>;
      default:
    }
  };

  return <>{getModule(module)}</>;
};
