import { Box, Image, Link } from "theme-ui";
import {
  ICalculatedStylesObject,
  isDesktopOrLargeDesktop,
  IStylesObject,
  theme,
} from "../../theme/theme";
import { useEffect, useRef, useState } from "react";
import { GrMail, GrFacebookOption, GrLinkedinOption } from "react-icons/gr";
import { GiShare } from "react-icons/gi";
import React from "react";
import { IPropsContactLinks } from "../../types";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import jobSearch from "../../devAssets/images/jobsearchGreen2.png";
gsap.registerPlugin(ScrollTrigger);

export const SocialMedia = ({
  email,
  daVinci,
  linkedIn,
  facebook,
}: IPropsContactLinks): JSX.Element | null => {
  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    const windowWidth = isBrowser ? window.innerWidth : 0;
    const handheldBreakpoint = parseInt(theme.breakpoints![1]);
    const handheld = windowWidth < handheldBreakpoint;
    setHandheld(handheld);
  }, []);

  const [handheld, setHandheld] = useState<boolean>();

  const styles: IStylesObject = {
    wrapperDesktop: {
      gridTemplateColumns: "1fr",
      position: "fixed",
      top: "50vh",
      left: "-60px",
      transform: "translate(0%, -50%)",
      mb: 0,
      transition: "all .5s",
      zIndex: 10,
      opacity: 1,
    },
    linkWrapperDesktop: {
      position: "relative",
      left: 0,
      transition: "all .5s",
      ":hover": {
        left: "10px",
      },
    },
    innerWrapperDesktop: {
      mb: "-4px",
      width: "60px",
      height: "60px",
      cursor: "pointer",
      textAlign: null,
      transition: "all .5s",
      ":hover": {
        scale: "1.2",
      },
    },
    wrapperHandheld: {
      position: "fixed",
      bottom: 0,
      right: "-60px",
      mb: 0,
      zIndex: 20,
      opacity: 0.5,
    },
    innerWrapperHandheld: {
      width: "60px",
      height: "60px",
      cursor: "pointer",
      textAlign: null,
    },
    backdrop: {
      position: "fixed",
      top: 0,
      left: 0,
      height: "100vh",
      width: "100vw",
      zIndex: 15,
    },
  };

  const iconStyles = {
    padding: "10%",
    zIndex: 10000,
    color: theme.colors?.primaryGreen as string,
  };

  const SocialMediaHandheld = () => {
    const [isVisible, setIsVisible] = useState(false);
    let mobileSocialMediaLinks: any = useRef(null);

    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    useEffect(() => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".RctValuesWrapper",
          start: "top bottom",
          end: "+=100vh",
          toggleActions: "play none reverse none",
          // markers: true,
        },
      });
      tl.fromTo(
        "#handheldSMWrapper",
        { x: 0, scale: 1.5 },
        { x: -60, scale: 1 }
      );
      mobileSocialMediaLinks.current = gsap
        .timeline()
        .from("#handheldSocialMediaLinks div", {
          duration: 0.1,
          x: 60,
          stagger: {
            each: 0.05,
            from: "end",
          },
        })
        .to(
          "#handheldSMWrapper",
          {
            opacity: 1,
          },
          "<"
        )
        .reverse();
      return () => {
        mobileSocialMediaLinks.current.kill();
      };
    }, []);

    useEffect(() => {
      mobileSocialMediaLinks.current.reversed(!isVisible);
    }, [isVisible]);

    return (
      <Box onClick={(e) => e.stopPropagation()}>
        <Box
          sx={styles.wrapperHandheld}
          className="wrapperHandheld"
          id="handheldSMWrapper"
        >
          <Box id="handheldSocialMediaLinks" ref={mobileSocialMediaLinks}>
            <Box>
              <Link className="socialMediaLink" href={linkedIn} target="_blank">
                <Box sx={styles.innerWrapperHandheld}>
                  <GrLinkedinOption
                    size="100%"
                    style={{
                      ...iconStyles,
                    }}
                  />
                </Box>
              </Link>
            </Box>
            <Box>
              <Link className="socialMediaLink" href={daVinci} target="_blank">
                <Box sx={styles.innerWrapperHandheld}>
                  {" "}
                  <Image
                    src={jobSearch}
                    height="100%"
                    style={{
                      ...iconStyles,
                    }}
                  />
                </Box>
              </Link>
            </Box>
            <Box>
              <Link className="socialMediaLink" href={facebook} target="_blank">
                <Box sx={styles.innerWrapperHandheld}>
                  <GrFacebookOption
                    size="100%"
                    style={{
                      ...iconStyles,
                    }}
                  />
                </Box>
              </Link>
            </Box>
            <Box>
              <Link
                className="socialMediaLink"
                href={"mailto:" + email}
                target="_blank"
              >
                <Box sx={styles.innerWrapperHandheld}>
                  <GrMail
                    size="100%"
                    style={{
                      ...iconStyles,
                    }}
                  />
                </Box>
              </Link>
            </Box>
          </Box>

          <Box sx={styles.innerWrapperHandheld} onClick={toggleVisibility}>
            <GiShare
              size="100%"
              style={{
                ...iconStyles,
              }}
            />
          </Box>
        </Box>
        {isVisible ? (
          <Box
            id="backdrop"
            onClick={() => setIsVisible(!isVisible)}
            sx={styles.backdrop}
          />
        ) : null}
      </Box>
    );
  };
  const SocialMediaDesktop = () => {
    useEffect(() => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".RctValuesWrapper",
          // markers: true,
          start: "center bottom",
          toggleActions: "play play reverse reverse",
        },
      });
      tl.fromTo(
        "#desktopSocialMediaWrapper div",
        {
          opacity: 0,
          x: 0,
          scale: 0.8,
        },
        {
          opacity: 1,
          x: 60,
          scale: 1,
          stagger: 0.1,
        }
      );
    }, []);

    return (
      <Box sx={styles.wrapperDesktop} id="desktopSocialMediaWrapper">
        <Box sx={styles.innerWrapperDesktop}>
          <Link sx={styles.linkWrapperDesktop} href={linkedIn} target="_blank">
            <GrLinkedinOption
              size="100%"
              style={{
                ...iconStyles,
              }}
            />
          </Link>
        </Box>
        <Box sx={styles.innerWrapperDesktop}>
          <Link sx={styles.linkWrapperDesktop} href={daVinci} target="_blank">
            <Image
              src={jobSearch}
              height="100%"
              style={{
                ...iconStyles,
              }}
            />
          </Link>
        </Box>
        <Box sx={styles.innerWrapperDesktop}>
          <Link sx={styles.linkWrapperDesktop} href={facebook} target="_blank">
            <GrFacebookOption
              size="100%"
              style={{
                ...iconStyles,
              }}
            />
          </Link>
        </Box>
        <Box sx={styles.innerWrapperDesktop}>
          <Link
            sx={styles.linkWrapperDesktop}
            href={"mailto:" + email}
            target="_blank"
          >
            <GrMail
              size="100%"
              style={{
                ...iconStyles,
              }}
            />
          </Link>
        </Box>
      </Box>
    );
  };

  return (
    <ModuleWrapper
      className="moduleWrapperContactLinks"
      anchorLink="RctSocialMedia"
      padding={false}
    >
      {handheld ? <SocialMediaHandheld /> : <SocialMediaDesktop />}
    </ModuleWrapper>
  );
};
