import {
  ICalculatedStylesObject,
  IStylesObject,
  theme,
} from "../../theme/theme";
import { ModuleWrapper } from "../../components/ModuleWrapper/ModuleWrapper";
import Logo from "../../devAssets/images/Logo.png";
import { useEffect } from "react";
import React from "react";
import { IPropsLogoTitle } from "../../types";
import { Box, Text } from "theme-ui";
import { AiOutlineDown } from "react-icons/ai";
import { RctImage } from "../../components/Image/Image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const styles: IStylesObject = {
  wrapper: {
    height: `100vh`,
    width: "100vw",
    position: "relative",
  },
  // logoD: {
  //   position: "absolute",
  //   width: ["60vw", "40vw", "40vw"],
  //   top: "50%",
  //   transform: "translate(0%,-50%)",
  //   left: ["20vw", "30vw", "30vw"],
  //   right: ["20vw", "30vw", "30vw"],
  // },
  // textD: {
  //   position: "absolute",
  //   top: "50%",
  //   transform: "translate(0%,-50%)",
  //   textAlign: "start",
  //   left: "100vw",
  // },
  logo: {
    position: "absolute",
    width: "60vw",
    maxWidth: "350px",
    bottom: "100vh",
    left: "50%",
    transform: "translateX(-50%)",
    opacity: 0,
  },
  text: {
    position: "absolute",
    top: "100vh",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
  },
  scrollText: {
    color: "primaryBlue",
  },
};

const RctLogoTitleDesktop = ({ title }: IPropsLogoTitle): JSX.Element => {
  const splittedTitle = title.split(" ");
  useEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: `.moduleWrapperLogoTitle`,
        pin: true,
        // markers: true,
        end: "+=2000",
        scrub: true,
        toggleActions: "play none reverse none",
      },
    });
    tl.to(`.logo`, {
      width: "20vw",
      left: 0,
    })
      .to(".text", {
        left: 0,
      })
      .to(
        `.logo`,
        {
          opacity: 0,
        },
        "<"
      );
  }, []);
  return (
    <ModuleWrapper
      className="moduleWrapperLogoTitle"
      anchorLink="RctLogo"
      isFullWidth={true}
      sx={styles.wrapper}
    >
      <RctImage className="logo" src={Logo} sx={styles.logoD}></RctImage>
      <Text variant="h1" className="text" sx={styles.textD}>
        {splittedTitle[0]}
        <br />
        {splittedTitle[1]}
      </Text>
      <Box className="scroll-down" sx={styles.scrollTextD}>
        <AiOutlineDown className="scroll-icon" />
      </Box>
    </ModuleWrapper>
  );
};
const RctLogoTitleHandheld = ({ title }: IPropsLogoTitle): JSX.Element => {
  const splitTitle = title.split(" ");
  useEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: `.moduleWrapperLogoTitle`,
        pin: true,
        toggleActions: "play play reverse reverse",
      },
    });
    tl.to(`.logo`, {
      bottom: "38vh",
      opacity: 1,
      duration: 1.5,
      ease: "back",
    }).to(
      ".text",
      {
        top: "65vh",
        duration: 1,
      },
      "<0.5"
    );
    const isBrowser = typeof window !== "undefined";
    isBrowser &&
      window.addEventListener("DOMContentLoaded", () => {
        tl.start;
      });
  }, []);
  return (
    <ModuleWrapper
      className="moduleWrapperLogoTitle"
      anchorLink="RctLogo"
      isFullWidth={true}
      sx={styles.wrapper}
      padding={false}
    >
      <RctImage className="logo" src={Logo} sx={styles.logo}></RctImage>
      <Text variant="h1" className="text" sx={styles.text}>
        {splitTitle[0]}
        <br />
        {splitTitle[1]}
      </Text>
      <Box className="scroll-down" sx={styles.scrollText}>
        <AiOutlineDown className="scroll-icon" />
      </Box>
    </ModuleWrapper>
  );
};
export const RctLogoTitle = ({ title }: IPropsLogoTitle): JSX.Element => {
  // const isBrowser = typeof window !== "undefined";
  // // isMobileOrHandheld() will not work here, because it is always true on loading
  // const windowWidth = isBrowser ? window.innerWidth : 0;
  // const handheldBreakpoint = parseInt(theme.breakpoints![1]);
  // const handheld = windowWidth < handheldBreakpoint;
  // return true ? (
  return (
    <RctLogoTitleHandheld
      title={title}
      id={""}
      internal={{
        type: "",
      }}
    />
  );
  // ) : (
  //   <RctLogoTitleDesktop
  //     title={title}
  //     id={""}
  //     internal={{
  //       type: "",
  //     }}
  //   />
  // );
};
